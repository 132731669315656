





















































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { StatusEnum, StatusEnumNumber } from "@/api/models/enums/statusPartenaireFASTT";
import { PartenaireFASTT, PartenaireFASTTUpdateModel } from "@/api/models/FASTT/partenaires/partenaire";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BFormCheckbox,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
  BBadge,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect
  },
})
export default class ListPartenaires extends Vue {
  statusPartenairesFASTT = StatusEnum;
  statusPartenairesFASTTNumber = StatusEnumNumber;

  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  timeout: any = null;
  searchQuery = "";
  pertinentOnly = false;
  isSortDirDesc = false;
  sortBy = "nom";
  isAddNewPartenaireSidebarActive = false;
  includePendingValidation = false;
  includeOldPartenaires = false;
  tableColumns = [
    { key: "nom", sortable: true },
    { key: "ville", sortable: true },
    { key: "telephone1", sortable: true },
    { key: "telephone2", sortable: true },
    { key: "statut", sortable: false },
    { key: "actions", sortable: false },
  ];
  paginatedListPartenaires = new PaginatedList<PartenaireFASTT>();

  async created() {
    await this.loadListPartenaires({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      includeOldPartenaires: this.includeOldPartenaires,
      includePendingValidation: this.includePendingValidation
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListPartenaires.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListPartenaires.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("includeOldPartenaires")
  @Watch("includePendingValidation")
  async paginationChanged() {
    await this.loadListPartenaires({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      includeOldPartenaires: this.includeOldPartenaires,
      includePendingValidation: this.includePendingValidation
    });
  }

  @Watch('searchQuery')
  @Watch("sortBy")
  @Watch("isSortDirDesc")
  async searchPartenaire() {
    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(async () => {
      await this.loadListPartenaires({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
        search: this.searchQuery,
        includeOldPartenaires: this.includeOldPartenaires,
        includePendingValidation: this.includePendingValidation
      })
    }, 1200);
  }

  askValidatePartenaire(partenaireId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir valider ce partenaire ?", {
        title: "Confirmation de refus",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.validatePartenaire(partenaireId);
        } else {
          return;
        }
      });

  }
  async validatePartenaire(partenaireId: string) {
    await this.$http.myressif.partenairesFASTT
        .getById(partenaireId)
        .then(async (response: PartenaireFASTT) => {
          let editPartenaire: PartenaireFASTTUpdateModel = {
            partenaireFASTTId: partenaireId,
            adresse : response.adresse,
            ville: response.ville,
            cp: response.cp,
            departementsAction: response.departementsAction.map((x: any) => x.id),
            nom : response.nom,
            statut : this.statusPartenairesFASTTNumber.Valide,
            tags : response.tags,
            telephone1 : response.telephone1,
            telephone2 : response.telephone2,
          }

          await this.$http.myressif.partenairesFASTT
            .update(partenaireId, editPartenaire)
            .then(
              async (res: any) => {
                successAlert.fire({
                  title: "Validation d'un partenaire FASTT",
                  text: "Validation effectuée avec succès",
                });

                await this.loadListPartenaires(null);
            },
            (error: any) => {
              if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            }
          );

        });
  }
  
  askRefusePartenaire(partenaireId: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir refuser ce partenaire ?", {
        title: "Confirmation de refus",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.refusePartenaire(partenaireId);
        } else {
          return;
        }
      });
  }
  async refusePartenaire(partenaireId: string) {
    await this.$http.myressif.partenairesFASTT
        .getById(partenaireId)
        .then(async (response: PartenaireFASTT) => {
          let editPartenaire: PartenaireFASTTUpdateModel = {
            partenaireFASTTId: partenaireId,
            adresse : response.adresse,
            ville: response.ville,
            cp: response.cp,
            departementsAction: response.departementsAction.map((x: any) => x.id),
            nom : response.nom,
            statut : this.statusPartenairesFASTTNumber.Refuse,
            tags : response.tags,
            telephone1 : response.telephone1,
            telephone2 : response.telephone2,
          }
          await this.$http.myressif.partenairesFASTT
            .update(partenaireId, editPartenaire)
            .then(
              async (res: any) => {
                successAlert.fire({
                  title: "Validation d'un partenaire FASTT",
                  text: "Rejet effectué avec succès",
                });

                await this.loadListPartenaires(null);
            },
            (error: any) => {
              if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
              ) {
                errorAlert.fire({
                  text: error.response.data.errors[
                  Object.keys(error.response.data.errors)[0]
                  ],
                });
              } else {
                errorAlert.fire({
                  text: error?.response?.data?.detail ?? error.message,
                });
              }
            }
          );

        });
  }
  
  goToDetailsTab(record: any){
    this.$router.push({
      name: "details-partenaires-fastt",
      params: { id: record }
    });
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      includeOldPartenaires: this.includeOldPartenaires,
      includePendingValidation: this.includePendingValidation
    };
    this.isSortDirDesc = !this.isSortDirDesc;

    this.loadListPartenaires(params);
  }

  async loadListPartenaires(params: any) {
    if (!params || params?.reset) {
      this.paginatedListPartenaires = new PaginatedList<PartenaireFASTT>();
    }
    await this.$http.myressif.partenairesFASTT
        .paginatedList(
          params?.pageNumber ?? 1,
          params?.pageSize ?? 10,
          params?.orderBy,
          params?.orderByDescending,
          params?.search ?? "",
          true,
          false,
          true
        )
        .then((response: PaginatedList<PartenaireFASTT>) => {
          this.paginatedListPartenaires = response;
        });
  }

  onRowSelected(record: any) {
    this.$router.push({
      name: "details-partenaires-fastt",
      params: { id: record.id }
    });
  }
}
